/*
 * @
 */

import {get, postJSON } from "@/utils/request"

export function pageQuery(data) {
    return get('/system/individualInvoice/listForH5', data)
}
export function findById(data) {
    return get('/system/individualInvoice/' + data)
}

export function ZFById(data) {
    return postJSON('/system/individualInvoice/zf', data)
}

export function cancelById(data) {
    return postJSON('/system/individualInvoice/cancel/mobile', data)
}